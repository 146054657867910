import React  from 'react';
import { Link, graphql } from "gatsby"
import { Table } from 'antd';
import 'antd/dist/antd.css';
// import { API, graphqlOperation } from 'aws-amplify';
import useHeadings from '../lib/useHeadings'

import Seo from "../components/seo"
import Layout from "../components/layout"
import { ListItem } from '../components/common/lists'
import Phone from '../components/common/phone'
import { schedule } from '../lib/tables'


const IndexPage = ({ data, location }) => {

  const tableOfContents = useHeadings();

  return (
    <>
      {/* console.log(process.env.NODE_ENV) */}
      <Layout location={location} toc={tableOfContents}>

        <h1 id="はじめに"> はじめに </h1>

        <p>
        このたびは当院のWebページを見ていただき、ありがとうございます。
        当院は、仙台駅東口から歩いて10分弱のところに位置するメンタルクリニックです。
        </p>

        <p>
        例えば以下のような症状のある方は、お気軽にご相談ください。
        下記以外の症状であっても、メンタルヘルス全般を対象といたします。
        </p>

        <ListItem 
          items={[
            "毎晩のように眠れない、すぐに目が覚めてしまう(不眠症) ", 
            "職場や学校でのストレスで気分が沈んだり、食欲がわかない(うつ病、適応障害)", 
            "突然息苦しくなったり、不安や緊張を感じやすい(パニック障害、不安障害)", 
            "いない人の声が聞こえてきたり、自分の考えが周囲に知られているなどの、奇妙な体験をする(統合失調症)", 
            "高齢の家族のもの忘れや徘徊などの症状で困っている(認知症) "]}
          >
        </ListItem>

        <h2 id="新患として受診するには"> 新患として受診するには </h2>

        <ListItem 
          items={[
            "当院を初めて受診される場合は、<strong> 予約が必要 </strong>です", 
            "新患予約の申し込みは、<strong>電話</strong>にて <strong>平日の午前10時から正午12時まで </strong> です",
            "予約の対象は<strong>当日分の新患枠のみ</strong>であり、翌日以降の新患枠をあらかじめ確保することはできません",
            "初診の予約は、<strong>新患専用ダイヤル(050-3355-3691)</strong>で受けつけます"
          ]}
          >
        </ListItem>

          <p>
          新患診察の開始時間は、以下の表のとおりです。
          </p>

          <Table 
            dataSource={schedule.newPatient.dataSource} 
            columns={schedule.newPatient.columns} 
            size="small" 
            pagination={false} 
            bordered 
            style={{marginBottom: "1rem"}}
          />

          予約の際には下記のような情報が必要となりますので、お知らせください。

          <ListItem
            items={[
              "氏名",
              "年齢",
              "性別",
              "電話番号",
              "現在の状況(1分程度で手短に)"
            ]}
          >
          </ListItem>

          <p>
          その他、初診について気になる点があれば、<Link to="/faq#初診の手続きについて"> 初診の手続きについて</Link>のページを参照してもらうか、メール等にてお尋ねください。
          </p>

          <Phone id="phone"></Phone>

      </Layout>
    </>
)}

export default IndexPage

export const Head = () => (
  <Seo />
)

export const pageQuery = graphql`
query PageQuery {
  site {
    siteMetadata {
      title
    }
  }
}`

